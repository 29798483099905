import { IUser } from '../Content/User';

export interface IOAuthRequest {
  access_token: string;
  provider: EOAuthProvider;
  last_name?: string;
  first_name?: string;
  bonus_code?: string;
  company_invite_code?: string;
}

export enum EOAuthProvider {
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  APPLE = 'apple',
}

export interface IAppleAuthResponse {
  user?: IAppleUser;
  authorization: IAppleAuthorization;
}

export interface IAppleAuthorization {
  code: string;
  id_token: string;
  state: string;
}

export interface IAppleUser {
  email: string;
  name: IAppleName;
}

export interface IAppleName {
  firstName: string;
  lastName: string;
}

export interface IAuthResponse {
  access_token?: string;
  user: IUser;
}
